import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import RobustWebSocket from "robust-websocket";

function SocketDebugger() {
  const [sendTestMessage, setSendTestMessage] = useState("");
  const [connectionState, setConnectionState] = useState("null");

  const [allMessages, setAllMessages] = useState("");

  const [message, setMessage] = useState("");
  const [action, setAction] = useState("");

  useEffect(() => {
    // https://github.com/nathanboktae/robust-websocket
    const socket = new RobustWebSocket(
      "wss://151zcallel.execute-api.eu-central-1.amazonaws.com/dev"
    );
    socket.addEventListener("open", () => setConnectionState("open"));
    socket.addEventListener("close", () => setConnectionState("closed"));
    socket.addEventListener("message", (messageEvent) =>
      setAllMessages(allMessages + messageEvent.data + "\n")
    );

    return () => {
      socket.close();
      socket.removeEventListener("message");
      socket.removeEventListener("close");
      socket.removeEventListener("open");
    };
  }, [allMessages]);

  function fetchTestMessage() {
    setSendTestMessage("LOADING ...");
    // see: data-models/socket-message-model.ts
    fetch(
      `https://htse21w0pa.execute-api.eu-central-1.amazonaws.com/add-event?message=${encodeURI(
        message
      )}&action=${encodeURI(action)}`
    )
      .then((response) => response.json())
      .then(
        (result) => setSendTestMessage(JSON.stringify(result)),
        (error) => console.error(error)
      );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1>Socket Debugger (connection: {connectionState})</h1>
      </Grid>
      <Grid item xs={12}>
        Message: <br />
        <TextField onChange={(e) => setMessage(e.target.value)}></TextField>
        <br />
        Action: <br />
        <TextField onChange={(e) => setAction(e.target.value)}></TextField>
        <br />
        &nbsp;
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            fetchTestMessage();
          }}
        >
          send test message
        </Button>
        <pre>{sendTestMessage}</pre>
      </Grid>
      <Grid item xs={12}>
        <p>Messages:</p>
        <pre>{allMessages}</pre>
      </Grid>
    </Grid>
  );
}

export default SocketDebugger;
