import { Button, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import RobustWebSocket from "robust-websocket";
import { fromEvent } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
//
// References:
// - https://de.wikipedia.org/wiki/RxJS
// - https://rxjs.dev
// - https://www.learnrxjs.io
// - https://rxmarbles.com
//

function SocketDebuggerRx() {
  const [sendTestMessage, setSendTestMessage] = useState("");
  const [connectionState, setConnectionState] = useState("...");

  const [messageTextField, setMessageTextField] = useState("");
  const [actionTextField, setActionTextField] = useState("");

  const [allMessages, setAllMessages] = useState("");
  const [rxDemoMessages, setRxDemoMessages] = useState("");

  useEffect(() => {
    // https://github.com/nathanboktae/robust-websocket
    const socket = new RobustWebSocket(
      "wss://151zcallel.execute-api.eu-central-1.amazonaws.com/dev"
    );
    socket.addEventListener("open", () => setConnectionState("open"));
    socket.addEventListener("close", () => setConnectionState("closed"));

    let subscription = fromEvent(socket, "message")
      .pipe(
        map((messageEvent) => JSON.parse(messageEvent.data)), // convert data string to object
        tap(
          (data) => setAllMessages(allMessages + JSON.stringify(data) + "\n") // add all messages
        ),
        filter((data) => data.action === "rxdemo"), // filter messages
        tap(
          (data) =>
            setRxDemoMessages(rxDemoMessages + JSON.stringify(data) + "\n") // add rxdemo messages
        )
      )
      .subscribe();

    return () => {
      console.log("use effect return");
      socket.close();
      socket.removeEventListener("close");
      socket.removeEventListener("open");
      subscription.unsubscribe();
    };
  }, [rxDemoMessages, allMessages]);

  function fetchTestMessage() {
    setSendTestMessage("LOADING ...");
    // see: data-models/socket-message-model.ts
    fetch(
      `https://htse21w0pa.execute-api.eu-central-1.amazonaws.com/add-event?message=${encodeURI(
        messageTextField
      )}&action=${encodeURI(actionTextField)}`
    )
      .then((response) => response.json())
      .then(
        (result) => setSendTestMessage(JSON.stringify(result)),
        (error) => console.error(error)
      );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <h1>Socket Debugger Rx (connection: {connectionState})</h1>
      </Grid>
      <Grid item xs={12}>
        Message: <br />
        <TextField
          onChange={(e) => setMessageTextField(e.target.value)}
        ></TextField>
        <br />
        Action: <br />
        <TextField
          onChange={(e) => setActionTextField(e.target.value)}
        ></TextField>
        <br />
        &nbsp;
        <br />
        <Button
          variant="outlined"
          onClick={() => {
            fetchTestMessage();
          }}
        >
          send test message
        </Button>
        <pre>{sendTestMessage}</pre>
      </Grid>
      <Grid item xs={12}>
        <p>All Messages:</p>
        <pre>{allMessages}</pre>
      </Grid>
      <Grid item xs={12}>
        <p>Messages with `action == rxdemo`:</p>
        <pre>{rxDemoMessages}</pre>
      </Grid>
    </Grid>
  );
}

export default SocketDebuggerRx;
