import { consoleLog } from "../util/consoleLog";
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
// import grimacesLogoEn from "../img/grimace_logo_en.svg";
import Button from "@mui/material/Button";
import PhotoContributions from "./photoContributions";
import Subscriptions from "./subscriptions";
import Journal from "./journal";
import {
  deleteFakeGames,
  removeAgedPhotos,
  writeGameEnd,
} from "../util/apiConnect";
import AddUser from "./addUser";
import SocketDebugger from "./socket-debugger/SocketDebugger";
import SocketDebuggerRx from "./socket-debugger/SocketDebuggerRx";

function Admin() {
  const [what2Render, setWhat2Render] = useState(null);
  const [deletedCount, setDeletedCount] = useState(null);
  const [gamesTouchedPhotos, setGamesTouchedPhotos] = useState([]);
  const [gamesPendingPhotos, setGamesPendingPhotos] = useState([]);
  const [gamesTouchedEnded, setGamesTouchedEnded] = useState([]);
  const [gamesPendingEnded, setGamesPendingEnded] = useState([]);

  const renderAdminTool = () => {
    switch (what2Render) {
      case "photoContributions":
        return <PhotoContributions />;
      case "Subscriptions":
        return <Subscriptions />;
      case "Journal":
        return <Journal />;
      case "DeleteFakeGames":
        return `${deletedCount} fake games deleted`;
      case "DeleteAgedPhotos":
        return (
          <>
            gamesTouchedPhotos, i.e. photos removed:
            <br />
            {gamesTouchedPhotos !== undefined
              ? gamesTouchedPhotos.map((game) => {
                  return <div key={game.gameId}>{game}</div>;
                })
              : "none"}
            <br />
            gamesPendingPhotos, i.e. too young to remove photos and participants
            expected to contribute
            <br />
            {gamesPendingPhotos !== undefined
              ? JSON.stringify(gamesPendingPhotos)
              : "none"}
          </>
        );
      case "UpdatedEndedGames":
        return (
          <>
            gamesTouchedEnded, i.e. gameEndTime set:
            <br />
            {gamesTouchedEnded !== undefined
              ? gamesTouchedEnded.map((game) => {
                  return <div key={game.gameId}>{JSON.stringify(game)}</div>;
                })
              : "none"}
            <br />
            gamesPendingEnded, i.e. too young to set gameEndTime
            <br />
            {gamesPendingEnded !== undefined
              ? gamesPendingEnded.map((game) => {
                  return <div key={game.gameId}>{JSON.stringify(game)}</div>;
                })
              : "none"}
          </>
        );
      case "AddUser":
        return <AddUser />;
      case "SocketDebugger":
        return <SocketDebugger />;
      case "SocketDebuggerRx":
        return <SocketDebuggerRx />;
      default:
        return <></>;
    }
  };

  const updateEndedGames = async () => {
    try {
      const dbResponse = await writeGameEnd();
      setGamesTouchedEnded(dbResponse.gamesTouched);
      setGamesPendingEnded(dbResponse.gamesPending);
      consoleLog.log(`dbResponse: ${JSON.stringify(dbResponse)}`);
    } catch (err) {
      consoleLog.log(err);
    }
  };

  const flushFakeGames = async () => {
    try {
      const dbResponse = await deleteFakeGames();
      setDeletedCount(dbResponse);
      consoleLog.log(`dbResponse: ${JSON.stringify(dbResponse)}`);
    } catch (err) {
      consoleLog.log(err);
    }
  };

  const flushAgedPhotos = async () => {
    try {
      const dbResponse = await removeAgedPhotos();
      consoleLog.log(`dbResponse: ${JSON.stringify(dbResponse)}`);
      setGamesTouchedPhotos(dbResponse.gamesTouched);
      setGamesPendingPhotos(dbResponse.gamesPending);
    } catch (err) {
      consoleLog.log(err);
    }
  };

  const stdStyle = {
    width: "90%",
    marginTop: "10px",
    marginBottom: "10px",
  };

  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        // style={{ width: "100%" }}
        style={{ minWidth: "250px", maxWidth: "700px" }}
      >
        <Grid item xs={4} style={{ width: "100%" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setWhat2Render("photoContributions");
            }}
            style={stdStyle}
          >
            Load 50 contributions
          </Button>
        </Grid>
        <Grid item xs={4} style={{ width: "100%" }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (window.confirm("Remove all aged photos?")) {
                flushAgedPhotos();
                setWhat2Render("DeleteAgedPhotos");
              }
            }}
            style={stdStyle}
          >
            Delete aged photos
          </Button>
        </Grid>
        <Grid item xs={4} style={{ width: "100%" }}>
          <Button
            variant="outlined"
            onClick={() => {
              if (window.confirm("Delete all fake games?")) {
                setWhat2Render("DeleteFakeGames");
                flushFakeGames();
              }
            }}
            style={stdStyle}
          >
            Delete fake games
          </Button>
        </Grid>
        <Grid item xs={4} style={{ width: "100%" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setWhat2Render("UpdatedEndedGames");
              updateEndedGames();
            }}
            style={stdStyle}
          >
            Update ended games
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={() => {
              setWhat2Render("AddUser");
            }}
            style={stdStyle}
          >
            Add User
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={() => {
              setWhat2Render("Subscriptions");
            }}
            style={stdStyle}
          >
            Show subscriptions
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={() => {
              setWhat2Render("SocketDebugger");
            }}
            style={stdStyle}
          >
            Socket Debugger
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            onClick={() => {
              setWhat2Render("SocketDebuggerRx");
            }}
            style={stdStyle}
          >
            Socket Debugger Rx
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            variant="outlined"
            style={stdStyle}
            onClick={() => {
              setWhat2Render("Journal");
            }}
          >
            Show journal
          </Button>
        </Grid>
        <Grid item xs={12} style={{ width: "100%" }}>
          <ul>
            <li>
              <a href="">just for real admins</a>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12}>
          {renderAdminTool()}
        </Grid>
        <Grid item xs={12}>
          <div id="actyvystPopupHolder"></div>
        </Grid>
      </Grid>
    </>
  );
}

export default Admin;
